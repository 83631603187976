@import "bourbon";

// =============================================================================
// Normalize https://necolas.github.io/normalize.css/
// =============================================================================

html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}body{margin:0}article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary{display:block}audio,canvas,progress,video{display:inline-block;vertical-align:baseline}audio:not([controls]){display:none;height:0}[hidden],template{display:none}a{background-color:transparent}a:active,a:hover{outline:0}abbr[title]{border-bottom:1px dotted}b,strong{font-weight:700}dfn{font-style:italic}h1{font-size:2em;margin:.67em 0}mark{background:#ff0;color:#000}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sup{top:-.5em}sub{bottom:-.25em}img{border:0}svg:not(:root){overflow:hidden}figure{margin:1em 40px}hr{-moz-box-sizing:content-box;box-sizing:content-box;height:0}pre{overflow:auto}code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}button,input,optgroup,select,textarea{color:inherit;font:inherit;margin:0}button{overflow:visible}button,select{text-transform:none}button,html input[type="button"],/* 1 */
input[type="reset"],input[type="submit"]{-webkit-appearance:button;cursor:pointer}button[disabled],html input[disabled]{cursor:default}button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0}input{line-height:normal}input[type="checkbox"],input[type="radio"]{box-sizing:border-box;padding:0}input[type="number"]::-webkit-inner-spin-button,input[type="number"]::-webkit-outer-spin-button{height:auto}input[type="search"]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box}input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none}fieldset{border:1px solid silver;margin:0 2px;padding:.35em .625em .75em}legend{border:0;padding:0}textarea{overflow:auto}optgroup{font-weight:700}table{border-collapse:collapse;border-spacing:0}td,th{padding:0}

// =============================================================================
// SASS Variables
// =============================================================================

// Colours: Site
// ---------------
$primary-color: #074294;
$site-background-color: #ececec;

// Colours: Buttons
// ---------------
$primary-btn-color: #c99b0d;
$secondary-btn-color: #dfad13;

// Colours: Header
// ---------------
$header-background-color: #091c67;
$header-text-color: #dfad13;

// Colours: Footer
// ---------------
$footer-background-color: #e7e7e7;

// Colours: Neutral
// ---------------
$color-neutral: #cdcdcd;
$color-neutral-light-1: #eeeeee;
$color-neutral-light-2: #f3f3f3;
$color-neutral-light-3: #fefefe;
$color-neutral-dark-1: #b2b2b2;

// Colours: Text
// ---------------
$text-color: #5b5b5b;
$text-color-light: #ffffff;
$link-color: $primary-color;

// Colours: Helpers
// ---------------
$success-green: #54c670;
$alert-yellow: #ffed8f;


// Media Queries
// ---------------
$screen-sm-min: 480px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1600px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);

// Misc
// --------------
$border-radius: 3px;
$section-max-width: 1250px;


// =============================================================================
// Mixins
// =============================================================================

// This is a simple mixin for card styles. Card styles are used on course
// cards, and password reset boxes. This is meant to work for everything
// -------------------

@mixin card {
  @include clearfix;
  border-radius: 3px;
  box-shadow: 1px 1px 0 #e2e2e2;
  position: relative;
  background: #ffffff;
}


// This makes text colour change if the background colour is too light or dark
// -------------------

@function dynamic-text($color) {
  @if (lightness($color) > 85) {
    @return $text-color; // Lighter background, return dark color
  } @else {
    @return $text-color-light; // Darker background, return light color
  }
}


// =============================================================================
// Table of contents
// =============================================================================

// 0. Basics
// 1. Type
// 2. Forms
// 3. Buttons
// 4. Layout
// 5. Course Cards
// 6. Header
// 7. Footer
// 8. Review Stars
// 9. Pagination
// 10. Site Landing Page
// 11. Shared product landing page styles
// 12. High Conversion landing page
// 13. Curriculum landing page
// 14. Bundle landing page
// 15. Multi Products
// 16. Sign up / Sign in
// 17. My Courses/Dashboard
// 18. All Courses/Student Dashboard
// 19. Checkout
// 20. Course Landing Page - Video Centric
// 21. Student/Affiliate edit account
// 22. Forgot Password

// =============================================================================
// 0. Basics
// =============================================================================

html, body {
  background: $site-background-color;
  color: $text-color;
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility;
  font-size: 1em; //16px
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

// =============================================================================
// 1. Type Converted to EM using http://pxtoem.com/
// =============================================================================


h1, h2, h3, h4, h5, h6, p {
  line-height: 1.5;
  margin-bottom: 1em;
  font-weight: 400;
}

h1 {
  font-size: 2.188em; //35px
}

h2 {
  font-size: 1.875em; //30px
}

h3 {
  font-size: 1.625em; //26px
}

h4 {
  font-size: 1.438em; // 23px
}

h5 {
  font-size: 1.125em; //18px
}

p {
  font-size: 1em; //16px
}

hr {
  border: none;
  background: $color-neutral;
  height: 1px;
  margin: 20px 0;
}

i, em {
  font-style: italic;
}

b, strong {
  font-weight: 600;
}

small {
  font-size: 80%;
}

a {
  color: $link-color;

  &:hover {
    color: darken($link-color, 12%);
    text-decoration: none;
  }
}

ul, ol {
  li {
    line-height: 1.5;
  }
}

// Removes list style
.unstyle {
  list-style: none;
  padding: 0;
}


// =============================================================================
// 2. Forms
// =============================================================================

input:focus::-webkit-input-placeholder { color: transparent; }
input:focus::-moz-placeholder { color:transparent; }

.form-control {
  background: $color-neutral-light-3;
  border: 1px solid $color-neutral;
  height: 40px;
  padding: 6px 15px;
  box-shadow: none;
  font-size: 1em; //16px
}

label {
  display: block;
}

// I am found only on the collections page.
input[type="search"] {
  @include transition(all 0.1s ease);
  box-sizing: border-box;
  border-radius: $border-radius;
  padding: 10px 15px;
  background: none;
  border: 1px solid transparentize($text-color,0.9);

  &:focus {
    background: #ffffff;
  }
}

.radio, .checkbox {
  display: block;
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  vertical-align: middle;
}

// Used on the Sign in/Sign up/Checkout flow. Styles the separator between the
// social sign in buttons and the sign in with email form.
.form__separator {
  margin: 15px 0;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
}


// =============================================================================
// 3. Buttons
// =============================================================================

.btn {
  display: inline-block;
  width: auto;
  border: none;
  border-radius: $border-radius;
  box-shadow: none;
  outline: none;
  font-weight: 500;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }
}

// Button: Modifiers
// -------------
.btn-large {
  padding: 12px;
  font-size: 1.125em; //18px
}

.btn-block {
  display: block;
  width: 100%;
}

// Button: Colours
// -------------
.btn-primary {
  background: $primary-btn-color;
  color: dynamic-text($primary-btn-color);

  &:hover,
  &:focus,
  &:active {
    background: darken($primary-btn-color, 10%);
    color: dynamic-text($primary-btn-color);
  }
}

.btn-secondary {
  background: $secondary-btn-color;
  color: dynamic-text($secondary-btn-color);

  &:hover,
  &:focus,
  &:active {
    background: darken($secondary-btn-color, 10%);
    color: dynamic-text($secondary-btn-color);
  }
}

.btn-default {
  background: $color-neutral-dark-1;
  color: #ffffff;

  &:hover,
  &:focus,
  &:active {
    background: darken($color-neutral-dark-1, 10%);
  }
}

// This is used as the Linkedin button on the sign up.
// ---------------
.linkedin-signin {
  background: #4875b4;
  color: $text-color-light;

  &:hover,
  &:focus,
  &:active {
    background: darken(#4875b4, 10%);
    color: $text-color-light;
  }
}

// =============================================================================
// 4. Layout
// =============================================================================

#wrap {
  @include clearfix;
  min-height: 100%;
  // Equal to footer height
  margin-bottom: -166px;
  background: lighten($site-background-color, 5%);
}

// Wrap: Media Queries
// ---------------

@media only screen
and (min-width: $screen-xxl-min) {
  #wrap {
    max-width: $screen-xxl-min;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid darken($site-background-color, 5%);
    border-right: 1px solid darken($site-background-color, 5%);
  }
}


// Container on the checkout/payment flow, sign in/sign up flow
// ---------------

#content-push {
  padding-top: 30px;
}

// Content-push: Media Queries
// ---------------
@media only screen
and (max-width: $screen-sm-max) {
  #content-push {
    padding-top: 25px;
  }
}

@media only screen
and (max-width: $screen-xs-max) {
  #content-push {
    padding-top: 15px;
  }
}


// Containers for interior content sections. Mostly surrounding the course cards
// ---------------
.site-landing__courses,
.products-wrapper,
.student-dashboard-wrapper,
.checkout-form,
.student-profile {
  max-width: $section-max-width;
  margin: 0 auto;
  padding: 35px 0;
}

.site-landing__courses {
  padding-top: 60px;
}

// Containers: Mobile
// ---------------

@media only screen
and (max-width: $screen-xs-max) {
  .site-landing__courses,
  .products-wrapper,
  .student-dashboard-wrapper,
  .checkout-form,
  .student-profile {
    padding: 20px 0;
  }
}


// Columns
// ---------------

.column {
  padding-left: 75px;
  padding-right: 75px;
}

// Columns: Mobile
// ---------------

@media only screen
and (min-width: $screen-lg-min) {
  .column {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media only screen
and (max-width: $screen-md-max) {
  .column {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media only screen
and (max-width: $screen-sm-max) {
  .column {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen
and (max-width: $screen-xs-max) {
  .column {
    padding-left: 15px;
    padding-right: 15px;
  }
}


// Misc
// ---------------

.visible {
  display: inline-block !important;
}

//==============================================================================
// 5. Course Cards
//==============================================================================

.card {
  @include card;
}

// Card: Modifiers
// -------------------

.card--block {
  display: block;
}

.card--padding-5 {
  padding: 5px;
}

.card--padding-15 {
  padding: 15px;
}

.card--padding-20 {
  padding: 20px;
}


// Card: Spacing
// -------------------

.card--bottom-margin-10 {
  margin-bottom: 10px;
}

.card--bottom-margin-20 {
  margin-bottom: 20px;
}


// Course card image
// --------------

.course-card {
  height: 451px;
  margin-bottom: 30px;
  cursor: pointer;
}

.course-card__img {
  @include border-top-radius(3px);
  width: 100%;
  height: auto;
}

.course-card__img-container {
  @include border-top-radius(3px);
  position: relative;
  overflow: hidden;
  max-height: 220px;
}


// Course card body
// --------------
.course-card__body {
  padding: 20px 20px 10px;
}

.course-card__instructor-name {
  font-size: 0.875em; //14px
}

.course-card__price {
  color: $primary-color;
  font-weight: 600;
  font-size: 0.875em; //15px
  text-align: right;
  line-height: 1;
  word-wrap: break-word;

  .free {
    text-transform: uppercase;
  }
}

.course-card__title {
  color: $text-color;
  font-size: 1.125em; //18px

  a {
    color: $text-color;
  }
}

.course-card__description {
  margin-bottom: 10px;
  color: lighten($text-color,25%);
  font-size: 0.938em; //15px
}


// Course card buttons
// --------------

.course-card__cta {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 20px;

  .btn {
    padding: 8px;
  }

  a {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.course-card__cta-secondary {
  margin-bottom: 10px;
}


// Progress Card
// ---------------
.progress-card {
  cursor: default;

  .course-card__body {
    padding-top: 10px;
  }

  .course-card__title {
    text-align: center;
  }

  .progress-card-progress {
    padding-top: 10px;
  }

  .progress-completion {
    color: $text-color;
    font-size: 1.875em; //30px
    text-align: center;
  }

  .progress-line {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: 3px;
    margin: 10px 0;

    .progress-line-percent {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $primary-color;
    }

    .progress-line-bar {
      height: 3px;
      background: $color-neutral;
    }
  }
}


// Cards: Media Queries
// ---------------

@media screen
and (min-width: $screen-lg-min) {
  .course-card__img-container {
    max-height: 185px;
  }
}

@media screen
and (min-width: $screen-md-min)
and (max-width: $screen-md-max) {

  .course-card {
    height: 481px;
  }

  .course-card__img-container {
    max-height: 235px;
  }

  .course-card__title {
    font-size: 1.063em; //17px
  }
}

@media screen
and (max-width: $screen-sm-max) {
  .course-card {
    max-width: 370px;
    height: auto;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 20px;
  }

  .course-card__cta {
    position: relative;
    margin-bottom: 0;
  }

  .course-card__body {
    .star-rating {
      margin: 10px 0;
    }
  }
}


//==============================================================================
// 6. Header Nav
//==============================================================================


header {
  padding-top: 20px;
  padding-bottom: 20px;
  background: $header-background-color;

  a {
    color: $header-text-color;

    &:hover,
    &:active,
    &:focus {
      color: darken($header-text-color, 20%);
    }
  }
}

// Navbar: Logo
// ---------------

.header-logo__image {
  width: auto;
  max-height: 35px;
  margin-right: 20px;
}

.header-school-name {
  display: inline-block;
  margin-right: 20px;
  font-size: 1.875em; //30px
}


// Navbar: Links
// ---------------

.header-nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.header-nav__item {
  font-size: 0.875em; //14px
  cursor: pointer;
}

// Navbar: Dropdown
// ---------------

.header-user-avatar {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin: -25px 5px -21px;
}

.dropdown {
  margin-left: 15px;
}

.dropdown-toggle {
  margin-left: 10px;
}

// Override the default Bootstrap styles
.dropdown-menu {
  top: 22px;
  right: 0;
  left: auto;
  border-radius: 0;
  background: $header-background-color;
  border: 0;

  & > li {
    text-align: right;
  }

  & > li > a {
    color: $header-text-color;
  }

  & > li > a:hover,
  & > li > a:active,
  & > li > a:focus {
    color: $header-background-color;
    background-color: $header-text-color;
  }
}

// Navbar: Mobile
// ---------------

.header-nav__mobile-btn {
  display: none;
  margin-right: 0;
  padding: 6px 10px;
  color: $header-text-color;
  border: 1px solid $header-text-color;
  background: none;
  border-radius: $border-radius;

  &:hover,
  &:active,
  &:focus {
    color: $header-background-color;
    background-color: $header-text-color;
  }
}


// Navbar: Media Queries
// ---------------

@media only screen
and (min-width: $screen-lg-min) {

  header .column {
    display: table;
    width: 100%;
  }

  .header-logo__container {
    display: table-cell;
    width: 1px;
    white-space: nowrap;
  }

  .header-nav__container {
    display: table-cell;
    vertical-align: middle;
  }

  .header-nav {
    display: table;
    width: 100%;
  }

  .header-nav__list {
    display: table-cell;
  }

  .header-nav__left,
  .header-nav__right {
    width: auto;
  }

  .header-nav__right {
    text-align: right;
  }

  .header-nav__item {
    display: inline-block;
    vertical-align: sub;
  }

  .header-nav__item + .header-nav__item {
    margin-left: 10px;
  }
}

@media only screen
and (max-width: $screen-md-max) {

  .header-nav {
    padding-top: 15px;
  }

  .header-school-name {
    margin-right: 85px;
  }

  .header-nav__container {
    display: none;
  }

  // Mobile menu adds open close class when toggled
  .header-nav__container.open{
    display: block;
  }

  .header-nav__item {
    display: block;
    text-align: center;
    font-size: 0.938em; //15px
  }

  .header-nav__link {
    display: block;
    padding: 10px 0;
  }

  .header-logo__container {
    position: relative;
  }

  .header-nav__mobile-btn {
    position: absolute;
    display: block;
    top: 50%;
    right: 0;
    @include transform(translateY(-50%));
  }

  .dropdown {
    display: none;
  }
}

//==============================================================================
// 7. Footer
//==============================================================================

// https://css-tricks.com/snippets/css/sticky-footer/
footer, #wrap:after {
  height: 166px;
}

footer {
  margin-left: auto;
  margin-right: auto;
  max-width: $screen-xxl-min;
  background: $footer-background-color;
  padding: 30px 0;
  color: dynamic-text($footer-background-color);


  a {
    color: dynamic-text($footer-background-color);

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
}

.footer-nav {
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0 0 30px 0;
}

.footer-nav__item {
  display: inline-block;
  font-size: 0.875em; //14px
}

.footer-nav__item + .footer-nav__item {
  margin-left: 15px;
}

.footer-copyright {
  margin-bottom: 10px;
  font-size: 0.750em; //12px
  opacity: 0.6;
}

.footer-powered-by {
  font-size: 0.750em; //12px
}

// Footer: Media Queries
// ---------------

@media only screen
and (min-width: $screen-xxl-min) {
  footer {
    max-width: $screen-xxl-min;
    margin-left: auto;
    margin-right: auto;
    border-left: 1px solid darken($site-background-color, 10%);
    border-right: 1px solid darken($site-background-color, 10%);
  }
}


//==============================================================================
// 8. Review Stars
//==============================================================================

.star-rating {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 90px;
  height: 18px;
  background: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjREREREREIiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=");
  background-size: contain;
  font-size: 0;
  white-space: nowrap;

  i {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 20%;
    height: 100%;
    opacity: 0;
    background: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkZERjg4IiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=");
    background-size: contain;
  }

  input {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 20%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  i ~ i {
    width: 40%;
  }

  i ~ i ~ i {
    width: 60%;
  }

  i ~ i ~ i ~ i {
    width: 80%;
  }

  i ~ i ~ i ~ i ~ i {
    width: 100%;
  }
}

.star-rating__input {
  input {
    position: relative;

    &:hover + i,
    &:checked + i {
      opacity: 1;
    }
  }
}

.star-rating__filled {
  position: relative;

  i {
    opacity: 1;
  }
}

.star_rating__single {
  width: 25px;
}


//==============================================================================
// 9. Pagination
//==============================================================================

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  background-color: $color-neutral-light-2;
  border-color: $color-neutral;
  color: $link-color;
}

.pagination > li > a,
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span {
    color: $link-color;
  border: 1px solid $color-neutral;
}


//==============================================================================
// 10. Site Landing Page
//==============================================================================

.site-landing__banner {
  position: relative;
  height: 400px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: dynamic-text($primary-color);
}

.site-landing__banner-content {
  @include transform(translate(-50%, -50%));
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: $section-max-width;
  text-align: center;
}

.product-cards__container {
  @include clearfix;
  @media only screen
  and (min-width: $screen-lg-min) {
    @include display(flex);
    @include justify-content(center);
    @include flex-wrap(wrap);
    // removes the before and after clear fixes for this row, to fix the flexbox in safari.
    &:before,
    &:after {
      content: normal;
    }
  }
}


// Site Landing: Media Queries
// ---------------

@media only screen
  and (max-width: $screen-sm-max) {
  .site-landing__banner {
    height: 300px;
  }

  .site-landing__banner--title {
    font-size: 1.875em; //30px
    margin-top: 0;
  }

  .site-landing__banner--subtitle {
    font-size: 1.250em; //20px
    margin: 0;
  }
}

@media only screen
and (max-width: $screen-xs-max) {

  .site-landing__banner{
    height: 250px;
  }

  .site-landing__banner--title {
    font-size: 1.625em; //26px
  }

  .site-landing__banner--subtitle {
    font-size: 0.938em; //15px
  }
}


//==============================================================================
// 11. Shared Product Landing Styles
//==============================================================================

.product-landing {
  background: #ffffff;
  font-size: 1em; //16px
  line-height: 1.8;

  section {
    @include clearfix;
    padding-top: 60px;
    padding-bottom: 60px;

    p:last-of-type {
      margin-bottom: 0;
    }

    p a {
      color: $link-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // This controls the title text on every section.
  // Ex: 'Course Overview'
  // --------------

  .section-title {
    margin: 0 0 42px;
    color: $primary-color;
    font-weight: 200;
    font-size: 2.250em; //36px
  }
}

// This is the contact information for the course.
// It's not found in the instructor section. It's found in the
// Course > Details section at the bottom. It can contain text and HTML
// ---------------

.course-contact-info {
  font-weight: bold;
}

// Product Landing: Product Banner (the giant image that spans across the top of the page)
// ---------------

.product-banner {
  position: relative;
  width: 100%;

  .title-block {
    width: 75%;
    margin: auto;
    padding: 0;
    font-weight: 900;
    text-align: center;
  }

  .product__title {
    margin: 0 0 30px;
    color: dynamic-text($primary-color);
    font-weight: 300;
    font-size: 2.438em; //39px
  }

  .product__subtitle {
    display: block;
    color: dynamic-text($primary-color);
    font-weight: 400;
    font-size: 1em; //16px
  }

  .product__private-title {
    color: dynamic-text($primary-color);
    line-height: 1;

    i {
      font-size: 1.125em; //18px
      vertical-align: middle;
    }
  }
}

// Pops open the venobox window
// ------------

.btn-intro-video {
  border: 1px solid $text-color-light;
  background: none;
  color: $text-color-light;
  font-size: 1em; //16px

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $text-color-light;
    background: $text-color-light;
    color: $primary-color;
  }
}

@media only screen
and (max-width: $screen-xs-max) {
  .btn-intro-video {
    padding-left: 12px;
    padding-right: 12px
  }
}

// Call to action blocks. They list in the page header and in a section
// inside the page body.
// ------------

.product-main-cta {
  text-align: center;

  .btn {
    padding: 15px 40px;
    font-weight: 400;
    font-size: 1.250em; //20px
  }
}

.product-body-button-block {
  .bundle-cta-tagline {
    color: $text-color;
  }
}

// Product Landing: Product Overview
// ------------

.product-overview {
  // This controls the width of the images people put in the redactor box
  // on the curriculum landing page
  img {
    max-width: 100%;
  }

  .readmore {
    position: relative;
  }

  .readmore-toggle {
    margin-top: 26px;
  }
}

// Shows up under the course description and is editable by the user
.course-contact-info {
  margin: 0;

  p {
    margin: 10px 0;
  }
}

// Product Landing: Course Reviews
// ------------

.course-review__container {
  margin-bottom: 48px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.course-review__name {
  margin-bottom: 18px;
}

.course-review__stars {
  margin-bottom: 12px;
}

.course-review__title {
  margin-bottom: 12px;
  font-weight: normal;
}

.review-button {
  display: inline-block;
  margin-top: 30px;
  cursor: pointer;
}

// Product Landing: Chapter/Curriculum section
// ------------

.chapter-info {
  padding: 0;
  list-style: none;

  li {
    float: left;
    display: inline-block;
    margin: 0 5px 5px 0;
    padding: 2px 10px 0;
    border-radius: 3px;
    background: $color-neutral-light-2;
    color: $text-color;
  }

  i {
    color: $primary-color;
    font-size: 1.125em; //18px
    vertical-align: middle;
  }

  span {
    font-weight: 600;
    font-size: 0.688em; //11px
  }
}

.toggle-content {
  padding: 5px 0;
  background: $color-neutral-light-2;
  color: darken($color-neutral-light-2, 50%);
  font-weight: 600;
  font-size: 0.813em; //13px
  text-align: center;
  cursor: pointer;
}


// Label used to show "Free" content on the landing pages
// ------------

.label {
  border-radius: $border-radius;
  display: inline-block;
  padding: 2px 5px;
  margin: 0 5px;
  float: left;
  background: $primary-color;
  color: $text-color-light;
}

.label-free {
  background: $success-green;
  float: none;
}

// Product Landing: Promotions
// This shows up when the user is given a coupon link
// course-url/?coupon=100
// ------------

.product-landing .course-promotion {
  padding: 0;

  .coupon {
    padding: 10px;
    font-size: 1em; //16px
  }

  .valid-coupon {
    background-color: $success-green;
    color: lighten($success-green, 50%);
  }

  .invalid-coupon {
    background-color: $alert-yellow;
    color: darken($alert-yellow, 50%);
  }
}

// Product Landing: Media Queries
// ------------

@media only screen
and (max-width: $screen-sm-max) {

  .product-landing {

    section {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    section p,
    section ul {
      font-size: 0.938em; //15px
    }

    .product__title {
      font-size: 1.750em !important; //28px
    }

    .section-title {
      margin-bottom: 24px;
      font-size: 1.750em !important; //28px
    }

    .title-block {
      width: 90%;
    }
  }
}

@media only screen
and (max-width: $screen-xs-max) {
  .product-landing {
    .product-main-cta .btn {
      padding: 12px;
      font-size: 1.125em; //18px
    }
  }
}


// =============================================================================
// 12. Default / High conversion landing page
// =============================================================================

.high-convert-landing .btn-intro-video {
  margin-bottom: 10px;
}

.high-convert-landing .product-main-cta {
  margin-top: 30px;
}

.high-convert-landing .course-instructor {
  margin-top: 48px;
  background: $color-neutral-light-2;
  color: $text-color;

  // This controls the width of the images people put in the redactor box
  // on the curriculum landing page
  // --------------
  img {
    max-width: 100%;
  }

  .instructor__img-wrap {
    margin-top: -120px;
    text-align: center;
  }

  .instructor__img {
    border-radius: 50%;
    margin-bottom: 10px;
    height: 162px;
    width: 162px;
    border: 5px solid #ffffff;
  }

  .instructor__details {
    margin-bottom: 36px;
  }

  .instructor__name {
    font-size: 1.250em; //20px
    font-weight: 600;
    text-align: center;
  }

  .instructor__title {
    text-align: center;
  }
}

// High Conversion landing: Course Curriculum
// --------------

.high-convert-landing .course-curriculum {
  background: darken($color-neutral-light-2, 5%);

  .section-title {
    color: $text-color;
  }

  // Styles chapter block in the curriculum list
  .curriculum-chapter {
    margin: 0 0 10px 0 !important;
  }

  // Container for the chapter header
  .curriculum-chapter-header {
    padding: 10px 15px !important;
    background: $primary-color !important;

    .chapter-time {
      color: $text-color-light;
      text-align: right;
    }
  }

  .chapter-header {
    font-weight: bold;
    color: dynamic-text($primary-color);
  }

  .chapter-list-item__link {
    display: block;

    &:hover {
      background: $color-neutral-light-2;
    }
  }

  .chapter-list-item {
    position: relative;
    padding: 5px 45px 5px 15px;
    color: $text-color;

    i {
      @include transform(translateY(-50%));
      position: absolute;
      top: 50%;
      right: 25px;
      font-size: 1.250em; //20px
      color: $text-color;
    }
  }

  // This is the button that open up the chapter contents list
  .chapter-toggle {
    border-radius: 0;
    border: 0;
    background: #ffffff;
  }

  .course-main-cta {
    padding-top: 36px;
  }
}

// High Conversion landing: Media Queries
// ---------------

@media only screen
and (max-width: $screen-xs-max) {

  .high-convert-landing {

    .course-instructor {
      margin-top: 60px;
    }

    .instructor__img-wrap {
      margin-top: -80px;
    }

    .instructor__img {
      height: 108px;
      width: 108px;
    }

    .curriculum-chapter-header {
      padding: 5px 15px !important;
    }

    .toggle-content {
      padding: 0 !important;
    }

    .course-curriculum  .chapter-time {
      text-align: left;
    }
  }
}


// =============================================================================
// 13. Curriculum Landing page
// =============================================================================

.curriculum-landing {
  .course-actions {
    padding: 25px 0;
    border-bottom: 1px solid $color-neutral;
    background: $color-neutral-light-2;
  }

  .section-title {
    color: $text-color;
  }

  .btn-intro-video {
    margin-top: 20px;
  }

  // Makes the two columns look like they match
  // ---------------

  .course-body {
    position: relative;
  }

  .sidebar-filler {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    border-left: 1px solid $color-neutral;
  }

  // Curriculum Landing: Course Includes List
  // Shows the little 1 video, 1 text, and course time elements
  // ---------------

  .course-includes {
    margin-bottom: 24px;

    ul {
      display: inline;
      padding: 0;
      color: $text-color;
      list-style: none;
    }

    li {
      display: inline-block;
      margin-right: 3px;
      margin-bottom: 6px;
      padding: 5px 6px 3px 4px;
      border: 1px solid $color-neutral;
      border-radius: $border-radius;
      background: #ffffff;
      color: $text-color;
      font-size: 0.813em; //13px
      white-space: nowrap;

      &:last-of-type {
        margin-right: 0;
      }
    }

    i {
      color: $primary-color;
      font-weight: 400;
      font-size: 1.125em; //18px
      line-height: 1;
      vertical-align: middle;
    }
  }

  // Curriculum Landing: Course Curriculum
  // ---------------

  .course-curriculum {
    padding-right: 20px;
  }

  .curriculum-chapter + .curriculum-chapter {
    margin-top: 16px;
  }

  .chapter-toggle {
    padding: 0;
    border: 1px solid $color-neutral;
    border-radius: $border-radius;
    background: #ffffff;
  }

  // Container for the chapter header
  .curriculum-chapter-header {
    padding: 10px 15px !important;

    .chapter-time {
      color: $text-color-light;
      text-align: right;
    }
  }

  .chapter-header {
    color: $primary-color;
    font-weight: 600;
    line-height: 1;
  }

  .chapter-list {
    padding: 5px 0;
  }

  .chapter-list-item {
    position: relative;
    padding: 5px 45px 5px 15px;
    color: $text-color;
    font-size: 0.875em; //14px

    i {
      @include transform(translateY(-50%));
      position: absolute;
      top: 50%;
      right: 25px;
      color: $text-color;
      font-size: 1.250em; //20px
    }
  }

  .chapter-time {
    float: right;
    display: inline-block;
    margin-top: 8px;
    padding: 0 10px;
    border-radius: 50%;
    background: $primary-color;
    color: $text-color-light;
    font-size: 0.875em; //14px
    text-align: center;
  }

  // Curriculum Landing: Course Details Sidebar
  // ---------------

  .course-details {
    padding: 0 0 40px 18px;

    .product-overview,
    .course-instructor {
      margin-bottom: 48px;
    }

    // This gets Google to fall into place
    .social-icons {
      font-size: 1px;

      // This gets Facebook to fall into place
      iframe {
        vertical-align: middle;
      }
    }

    // Set an optional width for your button wrappers
    .share-btn {
      display: inline-block;
      width: 75px;
      vertical-align: middle;
    }

    .less {
      overflow: hidden;
      height: 270px;
    }
  }

  .course-details__section {
    margin-bottom: 48px;
  }

  .instructor__details {
    @include display(flex);
    @include align-items(center);
    margin: 0 0 20px 0;
    font-weight: 600;
  }

  .instructor__img {
    width: 70px;
    height: 70px;
    margin-right: 20px;
    border: 1px solid $color-neutral;
    border-radius: 50%;
  }

  // Curriculum Landing: Multi-products
  // ---------------

  .multi-product__description {
    padding: 5px 10px;
    line-height: 1.5;

    .price {
      font-size: 1em; //16px
    }

    .title {
      font-size: 0.875em; //14px
    }
  }

  // Curriculum Landing: Media Queries
  // ---------------

  @media only screen
  and (max-width: $screen-md-max) {
    .course-body {
      .course-curriculum {
        margin-bottom: 40px;
      }
    }

    .course-curriculum {
      padding-right: 0;
    }

    .course-details {
      padding: 0;
    }

    .sidebar-filler {
      display: none;
    }
  }

  @media only screen
  and (max-width: $screen-sm-max) {
    .chapter-time {
      float: none;

      margin-top: 12px;
    }
  }

  @media only screen
  and (max-width: $screen-xs-max) {
    .course-includes {
      margin-bottom: 24px;
    }

    .course-actions {
      padding: 10px;
    }
  }
}


// =============================================================================
// 14. Bundle Landing Page
// =============================================================================

// Bundle content is the list of Products that makes up the bundle.
// We didn't use normal course cards because someone might have more than 50+
// courses which makes for a very long page.
// --------------

.bundle-content {
  background: $color-neutral-light-2;

  .section-title {
    color: $text-color;
  }
}

// a single line that sits under the button. Kind of a little extra upsell
.bundle-cta-tagline {
  margin-top: 10px;
  color: dynamic-text($primary-color);
}

.bundle-content__item {
  @include card;
  padding: 10px;

  .row {
    @include display(flex);
    @include align-items(center);
  }
}

.bundle-content__item-img {
  border-radius: $border-radius;
  height: auto;
  width: 100%;
}

.bundle-content__item-title {
  font-size: 1.125em; //18px
}

.bundle-content__item + .bundle-content__item,
.bundle-content__item + .bundle-course--hidden,
.bundle-course--hidden + .bundle-course--hidden {
  margin-top: 12px;
}

.bundle-see-more-content {
  padding-top: 32px;
}


// Bundle Landing Page: Media Queries
// --------------

@media only screen
and (max-width: $screen-xs-max) {

  .bundle-content__item-title {
    font-size: 1em; //16px;
  }
}


// =============================================================================
// 15. Multi Products
// If an admin sells multiple products on a single landing page you will get
// this section
// =============================================================================


.multi-products,
.course-multi-products {

  .multi-product__item {
    @include display(flex);
    margin: 0 0 6px 0;
  }

  .multi-product__buy {
    @include display(flex);
    padding: 0;
  }

  .multi-product__cta {
    border-radius: 0;
    @include display(flex);
    @include align-items (center);
    @include justify-content (center);
    @include flex(1);
    font-size: 1em; //16px
  }

  .multi-product__description {
    padding: 10px 18px;
    background: $color-neutral-light-2;

    .price {
      line-height: 1.5;
      font-weight: 600;
    }
  }
}

// Multi Products: Media Queries
// --------------

@media only screen
and (max-width: $screen-sm-max) {

  .multi-products,
  .course-multi-products {

    .multi-product__item {
      display: block;
      margin-bottom: 24px;
    }

    .multi-product__cta {
      padding: 3px;
    }
  }
}


//==============================================================================
// 17. Sign up / Sign in
//==============================================================================

.user-sign-up {

  .section-title {
    text-align: center;
    margin-bottom: 30px;
  }

  .sign_up__or,
  .sign_in__or {
    margin: 15px auto;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
  }

  .help-block {
    display: none;
  }

  .has-error .help-block {
    font-size: 0.750em; //12px
    margin: 0;
    padding: 0;
    display: block;
  }

  .checkbox {
    margin: 0;
  }

  .remember-me-link {
    line-height: 21px;
  }

  .btn-primary {
    font-size: 1em; //16px
    padding: 8px 0;
  }
}

// Linkedin button and any other social/login buttons
.user-sign-up__alt-logins {
  text-align: center;

  .btn + .btn {
    margin-left: 10px;
  }
}

.user-sign-up__bottom {
  padding: 30px;
  text-align: center;

  a {
    font-size: 0.938em; //15px
    color: $text-color;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

// Sign in/Sign up: Media Queries
// -----------------

@media only screen
and (max-width: $screen-sm-max) {

  .user-sign-up {
    .remember-me-link,
    .forgot-password {
      font-size: 0.875em; //14px
    }
  }
}


@media only screen
and (max-width: $screen-xs-max) {

  .user-sign-up__alt-logins {
    .btn + .btn {
      margin-top: 5px;
      margin-left: 0;
    }
  }
}


// =============================================================================
//  18. All Courses/Student Dashboard
// =============================================================================

.products__filters {
  margin-bottom: 35px;

  select {
    @include appearance(menulist);
  }

  [type="search"]{
    width: 100%;
  }
}

// Student Dashboard (enrollments)
// -------------

.student-dashboard__hero {
  padding: 40px 30px;
  background: #ffffff;
  border-bottom: 1px solid $color-neutral-light-1;
}

.student-dashboard__title {
  margin-top: 0;
  font-size: 1.875em; //30px
  font-weight: 300;
  line-height: 1;
  margin-bottom: 1.5rem;
}

// All Courses/Student Dashboard: Media Queries
// -------------

@media only screen
and (max-width: $screen-sm-max) {

  .products__filter-box {
    margin-bottom: 10px;
  }

  .student-dashboard__hero {
    padding: 20px 15px;
  }

  .student-dashboard__title {
    font-size: 1.625em; //26px
  }
}


// =============================================================================
//  19. Checkout
// =============================================================================

.checkout-form-container__inner {
  @include card;
  padding: 20px;
}

.checkout-form__body {
  .section-title {
    margin: 0 0 25px 0;
    line-height: 1.25;
    font-size: 1.250em; //20px
  }
}

.checkout-form__bottom {
  padding-top: 25px;
  text-align: center;

  a {
    color: $link-color;
  }
}

.checkout-form__purchase {
  padding: 0 10px;
  margin-bottom: 15px;
  text-align: center;
}

.checkout-form__purchase-img {
  border-radius: 2px;
  max-width: 180px;
  height: auto;
  margin-bottom: 15px;
}

.checkout-form__purchase-description {
  max-width: 90%;
  margin: 0 auto;
  font-size: 1.063em; //17px
}

// The secure checkout images on the bottom left
.order-security {
  padding-top: 20px;
  margin-bottom: 20px;
}

.coupon-form__coupon-code {
  [type="text"]{
    width: 100%;
  }
}

// Ensure thankyou page tracking pixel is not visible
img[src*="googleadservices.com"] {
  position:absolute;
  top: -2px;
  left: -2px;
}

// Checkout: Progress bar
// -------------

#progressbar {
  @include clearfix;
  margin: 0 auto 25px;
  padding: 0;
  width: 70%;
  counter-reset: step;
  list-style: none;
}

#progressbar li {
  list-style-type: none;
  color: $text-color;
  text-align: center;
  font-size: 0.688em; //11px
  width: 50%;
  float: left;
  position: relative;
  z-index: 2;

  &:before {
    border-radius: 50%;
    content: counter(step);
    counter-increment: step;
    width: 20px;
    height: 20px;
    line-height: 20px;
    display: block;
    font-size: 0.813em; //13px
    color: $text-color-light;
    background: #dadada;
    margin: 0 auto 5px auto;
    z-index: 2;
  }

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background: #dadada;
    position: absolute;
    left: calc( -50% + 10px);
    top: 9px;
    z-index: -1;
  }

  &:first-child:after {
    content: none;
  }

  &.active:before,
  &.active:after {
    background: $primary-color;
  }
}


// Checkout: Confirm Order
// -------------

// List of produts being bought and their total price
.order-product__list {
  font-size: 1.125em; //18px
  margin-bottom: 10px;
  text-align: left;
}

.order-product__total-amount,
.order-product__coupon-name {
  padding-top: 12px;
}

.order-product__coupon {

  .coupon-check {
    margin-bottom: 10px;
    text-align: right;
    font-size: 1em; //16px
  }

  .coupon {
    padding: 10px 0 20px;
  }
}

// Checkout: Payment Info
// -------------

.checkout-auth-container {

  .credit-card {
    padding-top: 10px;

    .btn {
      margin-top: 20px;
    }
  }

  .credit-card__expiry-date {
    .expiration-date {
      padding-top: 11px;
    }
  }

  .paypal-button-row {
    text-align: center;
  }

  .paypal-button-row__text {
    font-size: 11px;
    margin: 5px 0;
  }
}


// Checkout: Thank you
// -------------

.checkout-thankyou .multi-products {
  margin-bottom: 50px;
  padding-top: 20px;
}

.checkout-thankyou__course {
  padding: 5px 0;

  & + & {
    margin-top: 8px;
  }

  &:nth-of-type(even){
    background: $color-neutral-light-2;
  }
}

.checkout-thankyou__course-name {
  font-size: 1.125em; //18px
  line-height: 1.8;
}

.checkout-bundle__more_enrollments_limit {
  margin: 10px;
}


// Checkout: Thank you
// -------------

@media only screen
and (max-width: $screen-sm-max) {
  .checkout-thankyou__course-name,
  .checkout-thankyou__button {
    text-align: center;
  }

  .checkout-thankyou__course {
    padding: 10px;

    &:nth-of-type(even){
      background: none;
    }
  }
}


// Checkout: Errors
// -------------

.checkout-auth-container {
  #local-payment-errors,
  #coupon-errors,
  .payment-errors {
    display: none;
    color: red;
  }

  // Errors for bad credit card number, or invalid expiration
  #local-payment-errors {
    margin-bottom: 10px;
  }

  #coupon-errors {
    padding-top: 10px;
    font-size: 0.750em; //12px
  }
}

.checkout-form {
  #errors {
    border-radius: 3px;
    list-style: none;
    font-weight: 300;
    font-size: 0.875em; //14px
    background: #ffd4cc;
    color: #e75725;
    margin-top: 20px;
    padding: 15px;
    line-height: 145%;
  }

  .alert-error {
    font-size: 0.750em; //12px
    color: #e75725;
  }

  .error-title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .alert-error a {
    text-decoration: underline;
    color: inherit;
  }

  .alert-hide {
    display: none;
  }

  .has-error .form-control {
    border-color: #e75725;
  }
}

.checkout-form #errors {
  margin-top: 0;
  margin-bottom: 20px;
}


// Checkout: Media Queries
// -------------

@media only screen
and (min-width: $screen-md-min) {
  .coupon-form__coupon-button .btn {
    padding: 10px 15px;
  }
}

@media only screen
and (max-width: $screen-sm-max) {
  .credit-card__expiry-date {
    margin-bottom: 10px;

    .expiration-date {
      padding-top: 0;
    }
  }

  .checkout-form-container {
    .form-group {
      margin-bottom: 12px;
    }

    .section-title {
      font-size: 1.125em; //18px
    }
  }

  .checkout-form-container__inner {
    padding: 15px;
  }

  .coupon-form__coupon-code,
  .coupon-form__applied {
    margin-bottom: 10px;
  }

  .coupon-form__coupon-button {
    text-align: right;

    .btn {
      width: auto;
      min-width: 100px;
    }
  }

  #progressbar {
    width: 100%;
    margin-bottom: 15px;

    li:before {
      width: 10px;
      height: 10px;
      line-height: 10px;
      content: "\00a0";
    }

    li:after {
      left: calc( -50% + 5px);
      top: 5px;
    }
  }

  .checkout-form__purchase-img {
    max-width: 120px;
  }

  .checkout-form__purchase-description {
    font-size: 0.875em; //14px
    width: 100%;
  }
}

@media only screen
and (max-width: $screen-xs-max) {
  .order-product__list {
    font-size: 0.875em; //14px
  }

  .order-product__coupon .coupon-check {
    font-size: 0.875em; //14px
    margin-bottom: 20px;
  }
}


// =============================================================================
//  20. Course Landing Page - Video Centric
// =============================================================================

.video-landing {

  .product-banner {
    height: auto;
    padding: 100px 0 240px;
  }

  .product__title {
    font-weight: bold;
  }

  .title-block {
    margin-bottom: 15px;
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .course-description {
     position: relative;
  }

  .course-video {
    width: 50%;
    margin: -200px auto 60px;
    box-shadow: 5px 5px 29px -6px rgba(0,0,0,0.39);
  }

  .course-instructor {
    margin-top: 48px;
    background: $color-neutral-light-2;
    color: $text-color;

    img {
      max-width: 100%;
    }
  }

  .instructor__img-wrap {
    margin-top: -154px;
    text-align: center;
  }

  .instructor__details {
    margin-bottom: 36px;
  }

  .instructor__name {
    font-size: 1.625em; //26px
    text-align: center;
  }

  .instructor__title {
    text-align: center;
  }

  .instructor__img {
    border-radius: 50%;
    margin-bottom: 20px;
    height: 170px;
    width: 170px;
    box-shadow: 5px 5px 29px -6px rgba(0,0,0,0.2);
  }

  .curriculum-chapter + .curriculum-chapter {
    margin-top: 45px;
  }

  .chapter-name {
    font-size: 1.625em; //26px
    line-height: 1.2;
    padding-right: 30px;
    text-align: right;
  }

  .chapter-time {
    padding-top: 10px;
    padding-right: 30px;
    text-align: right;
  }

  .chapter-list {
    border-left: 1px solid $color-neutral-light-1;
  }

  .chapter-list-item {
    position: relative;
    padding: 4px 30px 4px 15px;

    i {
        position: absolute;
        right: 0;
        top: 3px;
        font-size: 26px;
        color: $text-color;
    }
  }

  .course-related-products {
    background: $color-neutral-light-1;
  }

  .multi-product__item {
    @include card;
    margin-bottom: 15px;
    padding: 15px;
    text-align: center;

    .price {
      font-size: 22px;
      text-transform: uppercase;
    }
  }

  .multi-product__description {
    margin-bottom: 20px;
  }
}


// Video Landing Page: Media Queries
// -----------------

@media only screen
and (max-width: $screen-md-max) {
  .video-landing {

    .product-banner {
      padding: 50px 0 200px;
    }
  }
}

@media only screen
and (max-width: $screen-sm-max) {
  .video-landing {

    .product-banner {
      padding: 30px 0 150px;
    }

    .course-video {
      width: 50%;
      margin: -150px auto 40px;
    }

    .curriculum-chapter + .curriculum-chapter {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid lighten($text-color, 55%);
    }

    .chapter-name {
      margin-bottom: 20px;
      text-align: left;
      font-size: 1.250em; //20px
    }

    .chapter-list {
      border-left: none;
    }

    .chapter-list-item {
      padding-left: 0;
    }

    .product__title {
      margin-bottom: 10px;
    }

    .instructor__img-wrap {
      margin-top: -100px;
    }

    .instructor__img {
      border-radius: 50%;
      height: 130px;
      width: 130px;
      box-shadow: 5px 5px 29px -6px rgba(0,0,0,0.2);
    }

    .instructor__details {
      margin-bottom: 18px;
    }
  }
}

@media only screen
and (max-width: $screen-xs-max) {
  .video-landing {

    .product-banner {
      padding: 30px 0 60px;
    }

    .course-video {
      width: 70%;
      margin: -55px auto 30px;
    }
  }
}


// =============================================================================
// 21. Student/Affiliate edit account
// =============================================================================

.student-profile {

  // Upload student instructor image
  .btn-upload {
    opacity: 0;
    display: inline-block;
    width: 0;
    width: 100%;
  }

  .account-content-profile-img {
    margin: 20px 0 40px;

    img {
      height: 100px;
      width: 100px;
    }
  }

  #feedback-area {
    padding-top: 10px;
    font-size: 13px;
  }

  #error_explanation {
    border-radius: 4px;
    position: relative;
    font-size: 14px;
    background: #ffd4cc;
    color: #e75725;
    padding: 12px 30px;
    margin: 0 0 30px 0;
    text-align: center;

    h2 {
      font-size: 14px;
      margin: 10px 0;
      color: #e75725;
    }

    ul {
      list-style: none;
    }
  }
}

// Student edit account: Content
// --------------

.account-content,
.affiliate-content {
  @include clearfix;
  @include card;
  padding: 20px;

  .account-content-footer,
  .affiliate-content-footer {
    padding-top: 30px;
  }
}

.account-content__back-link {
  margin-bottom: 30px;
  display: inline-block;
}


// Student edit account: Account Menu
// --------------

.account-menu,
.affiliate-menu {
  @include card;

  .list-group-item {
    padding: 15px 10px;
    border: 0;
  }

  a.list-group-item:hover,
  a.list-group-item:focus {
    color: $link-color;
    background: $color-neutral-light-2;
  }

  a.list-group-item.active,
  a.list-group-item.active:hover,
  a.list-group-item.active:focus {
    color: $link-color;
    background: $color-neutral-light-2;
    border-color: none;
  }

  .badge {
    background: $link-color;
    color: $text-color-light;
  }

  a.list-group-item.active > .badge,
  .nav-pills > .active > a > .badge {
    background: $link-color;
    color: $text-color-light;
  }
}

// =============================================================================
//  22. Forgot Password
// =============================================================================

.reset-password-container {
  @include card;
  padding: 20px;

  .reset-password__title {
    margin-top: 0;
  }
}

// =============================================================================
//  23. Single Task View
// =============================================================================

.single-task-view {
  background: #f4f5f7 image-url("defaults/bg-shapes-grey.png") top left;
  overflow-y: auto;

  .container {
   padding-top: 15px;
   margin-bottom: 20px;

    .thinkific-logo {
      width: 150px;
      margin-bottom: 10px;
    }
  }

  .single-task-view__card {
    @include card;
    padding: 20px;
  }

  .single-task-view__title {
    line-height: 1.25;
    margin-bottom: 0.6em;
  }

  .single-task-view__subtitle {
    margin-bottom: 1.5em;
  }
}

@media only screen
and (max-width: 767px) {

  .single-task-view  {
    .container {
      padding-top: 15px;
    }
  }
}



    .mountain-1-6-3v1-1-1 {
$dark-text: #000000;
$light-text: #ffffff;

@function dynamic-text($color) {
  @if (lightness($color) > 85) {
    @return $dark-text; // Lighter background, return dark color
  } @else {
    @return $light-text; // Darker background, return light color
  }
}
$brand-color: #074294 !default;
$brand-accent-color: dynamic-text($brand-color) !default;

$header-background-color: #091c67 !default;
$header-text-color: #dfad13 !default;

$footer-background-color: #e7e7e7 !default;
$footer-text-color: dynamic-text($footer-background-color) !default;

$button-primary-background: #c99b0d !default;
$button-primary-color: dynamic-text($button-primary-background) !default;

$button-secondary-background: #dfad13 !default;
$button-secondary-color: dynamic-text($button-secondary-background) !default;

// Coupons
// -----------------------------------------------------------
$success-green: #54c670;
$alert-yellow: #ffed8f;

$coupon__background: $success-green;
$coupon-invalid__background: $alert-yellow;

// Base
// ======================================
// Colors
// --------------------------------------
$base-white: #ffffff;
$base__background: $base-white;
$base__color: #636363;
$grey: #fafafa;

$mountain-accent: #95a7b6;
$mountain-accent-dark: #1d5870;


$brand-color: #63c6d2;
$brand-accent-color: $brand-color;

// header and footer vars are not overwritten

$button-primary-background: #63c6d2;
$button-primary-color: dynamic-text($button-primary-background);

$button-secondary-background: #56acb6;
$button-secondary-color: dynamic-text($button-secondary-background);

// Buttons
// --------------------------------------
$btn-primary__background: $button-primary-background;
$btn-primary__color: $button-primary-color;
$btn-primary__background--hover: darken($btn-primary__background, 10%);
$btn-primary__background--active: $btn-primary__background--hover;

$btn-secondary__background: $button-secondary-background;
$btn-secondary__color: $button-secondary-color;
$btn-secondary__background--hover: darken($btn-secondary__background, 10%);
$btn-secondary__background--active: $btn-secondary__background--hover;

$btn-border-radius: 0;

// Typography
// ---------------------
$headings__h1-decoration-background: $brand-color;
$headings__h1-color--light: #e6f8ff;

$links__color: #63c6d2;
$text__color--light: $base-white;


// Sections
// ======================================
// Header
// ---------------------
$header__color: $base-white;

// Intro Video
// ---------------------
$btn-intro-video__color: $button-secondary-color;
$btn-intro-video__background: $brand-color;
$btn-intro-video__hover: lighten($btn-intro-video__background, 10%);
$btn-intro-video__active: $btn-intro-video__hover;
$btn-intro-video__border: 1px solid rgba(255, 255, 255, 0.2);
// Mobile
$btn-intro-video__background--mobile: $grey;
$btn-intro-video__button-background--mobile: $button-secondary-background;
$btn-intro-video__button-color--mobile: $button-secondary-color;

// Reviews
// ---------------------
$course-review__background: $mountain-accent;
$course-review-container__background: $base-white;
$course-review-name__background: #f8f8f8;
$course-review-button__color: $links__color;
$course-review__read-more-background: $btn-primary__background;
$course-review__read-more-color: $btn-primary__color;
$course-review__read-more-background--hover: $btn-primary__background--hover;
$course-review__read-more-color--hover: $btn-primary__color;


// Instructor
// ---------------------
$course-instructor__background: none;
$course-instructor__background--breakpoint-small: $base-white;

// Product Overview
// ---------------------
$product-overview__background: $base-white;

// Related Products
// ---------------------
$related-products__background: $mountain-accent-dark;
$related-products__color: $brand-accent-color;
$related-products-product-item__background: $base-white;
$related-products__title: $base-white;
$related-products__button-background: $button-primary-background;
$related-products__button-color: $button-primary-color;
$related-products__section-title: $base-white;

// Course Benefits
// ---------------------
$course-benefits__background: #104155;

// Course Contents
// ---------------------
$course-contents__background: $mountain-accent;
$course-contents-inner__background: $base-white;
$course-contents__icon-color: grey;

// Course Curriculum
// ---------------------
$course-curriculum__background: $base-white;
$course-curriculum__color: grey;
$course-curriculum__color--hover: $mountain-accent-dark;
$course-curriculum-header__border: 1px solid #e7e7e7;
$course-curriculum-header__color: black;
$course-curriculum-chapter-header__color: grey;
$label-free__background: $mountain-accent-dark;
$chapter-time__background: $label-free__background;

// =============================================================================
//  23. Mountain Landing
// =============================================================================

// background: $base__background;
// color: $base__color;
// line-height: 1.5;

/* Mountain Theme: Global
------------*/

/* Mountain Theme: Buttons
------------*/

a {
  cursor: pointer;
}

.btn-primary {
  background: $btn-primary__background;
  color: $btn-primary__color;
  font-weight: 600;

  &:hover, 
  &:active {
    background: $btn-primary__background--active;
  }
}

.btn-secondary {
  background: $btn-secondary__background;
  color: $btn-secondary__color;
  font-weight: 600;

  &:hover, 
  &:active {
    background: $btn-secondary__background--hover;
  }
}

/* Mountain Theme: Typography
------------*/

h1 {
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: 600;

  &.title-center {
    text-align: center;

    &:before {
      margin: 0 auto;
    }
  }

  &.title-light {
    color: $headings__h1-color--light;
  }
}

p {
  margin-bottom: 1.5rem;
  font-size: 16px;
  line-height: 1.8;
}

a {
  color: $links__color;
}

.body-light {
  color: $text__color--light;
}

@media only screen
and (max-width: $screen-sm-max) {
  h1 {
    margin-bottom: 25px;
    font-size: 26px;
    letter-spacing: 0;
  }

}


/* Mountain Theme: Header
------------*/

.landing-header {
  padding: 233px 0;
  background-position: center;
  background-size: cover;
  color: $header__color;

  .product__title {
    margin: 0 0 0.5em;
    font-size: 62px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 1;
  }

  .product__subtitle {
    display: block;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .btn-primary,
  .btn-secondary {
    margin-bottom: 3px;
    padding: 10px 40px;
    font-size: 16px;
  }

  .row {
    @include display(flex);
    @include justify-content(center);
    position: relative;
  }

  .btn-intro-video {
    @include transform(translate(-50%, -50%));
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 140px 60px;
    border: $btn-intro-video__border;
    background: $btn-intro-video__background;


    &:focus {
      outline: none;
    }

    &:before {
      display: block;
      font-family: thinkific;
      font-size: 40px;
      content: '\66';
    }
  }
}

.btn-intro-video {
  color: $btn-intro-video__color;

  &:hover, 
  &:active {
    color: $btn-intro-video__hover;
  }

}

@media only screen
and (max-width: $screen-md-max) {
  .landing-header {
    padding: 150px 0;

    .product__title {
      font-size: 50px;
    }
  }

}

@media only screen
and (max-width: $screen-sm-max) {
  .landing-header {
    padding: 60px 0 40px;

    .product__title {
      font-size: 30px;
    }
  }
}

/* Mountain Theme: Intro Video Mobile
------------*/

.intro-video-mobile {
  padding: 15px 0;
  background: $btn-intro-video__background--mobile;
  text-align: center;

  .btn {
    border-radius: $btn-border-radius;
    background: $btn-intro-video__button-background--mobile;
    color: $btn-intro-video__button-color--mobile;
  }
}

.venobox {
  max-height: 100%;
}

/* Mountain Theme: Course Review
------------*/  

.course-review {
  padding: 80px 0;
  background: $course-review__background;
}

.course-review__wrapper,
.more-reviews-toggle {
  width: 100%;
  margin: 0;
}

.course-review__flex-grid-item {
  display: block;
  margin-bottom: 15px;
}

.course-review__container {
  position: relative;
  padding-top: 25px;
  background: $course-review-container__background;
  overflow: auto;
  
  & > * {
    padding: 0 25px;
  }

  & > .review--partial, 
  & > .review--complete {
    margin-bottom: 10px;
  }
}


.course-review__title {
  margin-top: 10px;
  font-size: 18px;
}

.course-review__text {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 1.5;
}

.course-review__name {
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 0 25px 10px;
  // background: $course-review-name__background;
  // font-size: 14px;
  font-weight: 600;
}

.review__show-more,
.review__show-less {
  margin: 5px 0;
  padding: 5px 0;
  border: none;
  background: transparent;
  color: $links__color;
  font-family: inherit;
  font-size: 15px;

  &:hover, 
  &:active {
    background: transparent;
    color: $links__color;
    text-decoration: underline;
  }
}

.course-review__button {
  padding-top: 20px;
  text-align: center;

  .review-button {
    margin-top: 0;
    padding: 1em;
    border-radius: $btn-border-radius;
    color: $course-review__read-more-color;
    background: $course-review__read-more-background;
    cursor: pointer;

    &:hover,
    &:active {
      color: $course-review__read-more-color--hover;
      background: $course-review__read-more-background--hover;
    }
 }
}

@media only screen
and (max-width: $screen-md-max) {

  .course-review__flex-grid-item {
    margin-bottom: 1em;
  }
  .course-review__container {
    margin-top: 0;
    margin-bottom: 1em;
  }
}


@media only screen
and (max-width: $screen-sm-max) {
  .course-review {
    padding: 1.5em 0 2em;
  }

  .course-review__text {
    font-size: 14px;
  }

  .course-review__title {
    font-size: 16px;
  }

  .review__show-more,
  .review__show-less {
    font-size: 14px;
  }
}


/* Mountain Theme: Course Instructor
------------*/

.course-instructor {
  padding: 60px 0;
  background: $course-instructor__background;
  background-position: bottom left;
  background-size: cover;
}

.instructor__details {
  overflow: auto;
}

.instructor__img {
  display: inline-block;
  max-width: 125px;
  max-height: 125px;
  margin-bottom: 25px;
  border: 1px solid #eaeaea;
  border-radius: 50%;
}

/* Mountain Theme: Product Overview
------------*/

.product-overview {
  padding: 60px 0;
  background: $product-overview__background;
}

/* Mountain Theme: Related Products
------------*/

.related-products {
  padding: 60px 0 80px;
  background: $related-products__background;

  h1 {
    &:before {
      background: $brand-accent-color;
    }
  }
}

.theme-mountain-multi-product__item {
  @include display(flex);
  @include align-items(center);
  @include justify-content(space-between);
  margin-bottom: 15px;
  padding: 20px;
  background: $related-products-product-item__background;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.theme-mountain-multi-product__section-title {
  color: $related-products__section-title;
}

.theme-mountain-multi-product__item + .theme-mountain-multi-product__item {
  margin-top: 10px;
}

.theme-mountain-multi-product__price {
  font-size: 26px;
  font-weight: 600;
}

.theme-mountain-multi-product__buy {}

.theme-mountain-multi-product__title {
  padding-right: 1em;
  padding-left: 1em;
}

.theme-mountain-multi-product__cta {
  padding: 8px 30px;
  background: $related-products__button-background;
  color: $related-products__button-color;
  border-radius: $btn-border-radius;

  &:hover, 
  &:active {
    background: lighten($related-products__button-background, 10%);
    color: $related-products__button-color;
  }
}


@media only screen
and (max-width: $screen-md-max) {

  .theme-mountain-multi-product__price {
    margin-bottom: 15px;
  }

  .theme-mountain-multi-product__item {
    display: block;
    padding: 10px;
    text-align: center;
  }

  .theme-mountain-multi-product__title {
    margin-bottom: 15px;
    padding-right: 0;
    padding-left: 0;
  }
}


/* Coupons
------------*/
.course-promotion {
  .coupon {
    padding: 20px 0;
    color: #ffffff;

    &.valid-coupon {
      background: $coupon__background;
    }

    &.invalid-coupon {
      background: $coupon-invalid__background;
      color: dimgrey;
    }
  }

}


/* Mountain Theme: Course Benefits
------------*/

.course-benefits {
  padding: 60px 0;
  background: $course-benefits__background;
}

.course-benefits__item {
  margin-bottom: 30px;

  p {
    margin-bottom: 0;
  }
}

@media only screen
and (max-width: $screen-sm-max) {
  .course-benefits__item {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}


/* Mountain Theme: Course Contents
------------*/

.course-contents {
  padding: 60px 0;
  background: $course-contents__background;
}

.course-contents-types {
  @include display(flex);
  @include justify-content(center);
  @include flex-flow(row wrap);
  padding: 0;
  list-style: none;
  text-align: center;
}

.course-contents__inner {
  padding: 25px 25px 42px;
  border: 1px solid #e9e9e9;
  background: $course-contents-inner__background;
}

.course-contents-types__item {
  @include flex-shrink(0);
  margin-bottom: 15px;
  padding: 0 20px;

  i {
    display: inline-block;
    margin-bottom: 10px;
    color: $course-contents__icon-color;
    font-size: 55px;
  }

  span {
    display: block;
  }
}


@media only screen
and (max-width: $screen-md-max) {
  .course-contents-types__item {
    @include flex-basis(25%);
    padding: 0;
  }
}

@media only screen
and (max-width: $screen-sm-min) {
  .course-contents__inner {
    padding-bottom: 25px;
  }

  .course-contents-types__item {
    @include flex-basis(50%);
    padding: 10px 0;
  }
}

/* Mountain Theme: Course Curriculum
------------*/

.course-curriculum {
  padding: 80px 0;
  background: $course-curriculum__background;
  color: $course-curriculum__color;

  a {
    color: inherit;

    &:hover {
      color: $course-curriculum__color--hover;
    }
  }

  ul {
    padding-left: 2em;
  }

  li {
    margin-bottom: 1em;
    list-style-type: disc;
  }

  .curriculum-chapter-header {
    padding-bottom: 18px;
    margin-bottom: 24px;
    color: $course-curriculum-chapter-header__color;
    border-bottom: $course-curriculum-header__border;
  }

  .chapter-list-item__title {
    line-height: 1.5;
  }

  .curriculum-chapter + .curriculum-chapter {
    margin-top: 42px;
  }

  .chapter-header {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
  }

  .chapter-list-item + .chapter-list-item {
    margin-top: 18px;
  }

  .chapter-time {
    display: inline-block;
    padding: 3px 20px;
    float: right;
    border-radius: 200px;
    background: $chapter-time__background;
    color: dynamic-text($chapter-time__background);
    font-size: 1.1rem;
    text-align: right;
  }

  .label-free {
    padding: 5px;
    background: $label-free__background;
    font-size: 0.9em;
  }
}

@media only screen
and (max-width: $screen-sm-min) {
  .course-curriculum {

    .chapter-header {
      display: block;
      margin-bottom: 15px;
    }

    .chapter-time {
      float: none;
      text-align: left;
    }
  }
}




// @import 'https://fonts.googleapis.com/css?family=Raleway';
.page-content-container {
  font-family: 'Open Sans', sans-serif !important;
}

h1 {
  font-weight: 100;
  text-transform: uppercase;

  &:before {
    display: block;
    position: relative;
    top: -20px;
    width: 60px;
    height: 3px;
    background: $headings__h1-decoration-background;
    content: '';
  }
}

.theme-mountain-multi-product__section-title {
  text-align: center;
}

.theme-mountain-multi-product__price {
  font-weight: 100;
}

.theme-mountain-multi-product__section-title::before {
  margin: 0 auto;
}

}

    .mountain-1-6-3v1-1-0 {
$dark-text: #000000;
$light-text: #ffffff;

@function dynamic-text($color) {
  @if (lightness($color) > 85) {
    @return $dark-text; // Lighter background, return dark color
  } @else {
    @return $light-text; // Darker background, return light color
  }
}
$brand-color: #074294 !default;
$brand-accent-color: dynamic-text($brand-color) !default;

$header-background-color: #091c67 !default;
$header-text-color: #dfad13 !default;

$footer-background-color: #e7e7e7 !default;
$footer-text-color: dynamic-text($footer-background-color) !default;

$button-primary-background: #c99b0d !default;
$button-primary-color: dynamic-text($button-primary-background) !default;

$button-secondary-background: #dfad13 !default;
$button-secondary-color: dynamic-text($button-secondary-background) !default;

// Coupons
// -----------------------------------------------------------
$success-green: #54c670;
$alert-yellow: #ffed8f;

$coupon__background: $success-green;
$coupon-invalid__background: $alert-yellow;

// Base
// ======================================
// Colors
// --------------------------------------
$base-white: #ffffff;
$base__background: $base-white;
$base__color: #636363;
$grey: #fafafa;

$mountain-accent: #95a7b6;
$mountain-accent-dark: #1d5870;


$brand-color: #63c6d2;
$brand-accent-color: $brand-color;

// header and footer vars are not overwritten

$button-primary-background: #63c6d2;
$button-primary-color: dynamic-text($button-primary-background);

$button-secondary-background: #56acb6;
$button-secondary-color: dynamic-text($button-secondary-background);

// Buttons
// --------------------------------------
$btn-primary__background: $button-primary-background;
$btn-primary__color: $button-primary-color;
$btn-primary__background--hover: darken($btn-primary__background, 10%);
$btn-primary__background--active: $btn-primary__background--hover;

$btn-secondary__background: $button-secondary-background;
$btn-secondary__color: $button-secondary-color;
$btn-secondary__background--hover: darken($btn-secondary__background, 10%);
$btn-secondary__background--active: $btn-secondary__background--hover;

$btn-border-radius: 0;

// Typography
// ---------------------
$headings__h1-decoration-background: $brand-color;
$headings__h1-color--light: #e6f8ff;

$links__color: #63c6d2;
$text__color--light: $base-white;


// Sections
// ======================================
// Header
// ---------------------
$header__color: $base-white;

// Intro Video
// ---------------------
$btn-intro-video__color: $button-secondary-color;
$btn-intro-video__background: $brand-color;
$btn-intro-video__hover: lighten($btn-intro-video__background, 10%);
$btn-intro-video__active: $btn-intro-video__hover;
$btn-intro-video__border: 1px solid rgba(255, 255, 255, 0.2);
// Mobile
$btn-intro-video__background--mobile: $grey;
$btn-intro-video__button-background--mobile: $button-secondary-background;
$btn-intro-video__button-color--mobile: $button-secondary-color;

// Reviews
// ---------------------
$course-review__background: $mountain-accent;
$course-review-container__background: $base-white;
$course-review-name__background: #f8f8f8;
$course-review-button__color: $links__color;
$course-review__read-more-background: $btn-primary__background;
$course-review__read-more-color: $btn-primary__color;
$course-review__read-more-background--hover: $btn-primary__background--hover;
$course-review__read-more-color--hover: $btn-primary__color;


// Instructor
// ---------------------
$course-instructor__background: none;
$course-instructor__background--breakpoint-small: $base-white;

// Product Overview
// ---------------------
$product-overview__background: $base-white;

// Related Products
// ---------------------
$related-products__background: $mountain-accent-dark;
$related-products__color: $brand-accent-color;
$related-products-product-item__background: $base-white;
$related-products__title: $base-white;
$related-products__button-background: $button-primary-background;
$related-products__button-color: $button-primary-color;
$related-products__section-title: $base-white;

// Course Benefits
// ---------------------
$course-benefits__background: #104155;

// Course Contents
// ---------------------
$course-contents__background: $mountain-accent;
$course-contents-inner__background: $base-white;
$course-contents__icon-color: grey;

// Course Curriculum
// ---------------------
$course-curriculum__background: $base-white;
$course-curriculum__color: grey;
$course-curriculum__color--hover: $mountain-accent-dark;
$course-curriculum-header__border: 1px solid #e7e7e7;
$course-curriculum-header__color: black;
$course-curriculum-chapter-header__color: grey;
$label-free__background: $mountain-accent-dark;
$chapter-time__background: $label-free__background;

// =============================================================================
//  23. Mountain Landing
// =============================================================================

// background: $base__background;
// color: $base__color;
// line-height: 1.5;

/* Mountain Theme: Global
------------*/

/* Mountain Theme: Buttons
------------*/

a {
  cursor: pointer;
}

.btn-primary {
  background: $btn-primary__background;
  color: $btn-primary__color;
  font-weight: 600;

  &:hover, 
  &:active {
    background: $btn-primary__background--active;
  }
}

.btn-secondary {
  background: $btn-secondary__background;
  color: $btn-secondary__color;
  font-weight: 600;

  &:hover, 
  &:active {
    background: $btn-secondary__background--hover;
  }
}

/* Mountain Theme: Typography
------------*/

h1 {
  margin-bottom: 50px;
  font-size: 30px;
  font-weight: 600;

  &.title-center {
    text-align: center;

    &:before {
      margin: 0 auto;
    }
  }

  &.title-light {
    color: $headings__h1-color--light;
  }
}

p {
  margin-bottom: 1.5rem;
  font-size: 16px;
  line-height: 1.8;
}

a {
  color: $links__color;
}

.body-light {
  color: $text__color--light;
}

@media only screen
and (max-width: $screen-sm-max) {
  h1 {
    margin-bottom: 25px;
    font-size: 26px;
    letter-spacing: 0;
  }

}


/* Mountain Theme: Header
------------*/

.landing-header {
  padding: 233px 0;
  background-position: center;
  background-size: cover;
  color: $header__color;

  .product__title {
    margin: 0 0 0.5em;
    font-size: 62px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 1;
  }

  .product__subtitle {
    display: block;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .btn-primary,
  .btn-secondary {
    margin-bottom: 3px;
    padding: 10px 40px;
    font-size: 16px;
  }

  .row {
    @include display(flex);
    @include justify-content(center);
    position: relative;
  }

  .btn-intro-video {
    @include transform(translate(-50%, -50%));
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 140px 60px;
    border: $btn-intro-video__border;
    background: $btn-intro-video__background;


    &:focus {
      outline: none;
    }

    &:before {
      display: block;
      font-family: thinkific;
      font-size: 40px;
      content: '\66';
    }
  }
}

.btn-intro-video {
  color: $btn-intro-video__color;

  &:hover, 
  &:active {
    color: $btn-intro-video__hover;
  }

}

@media only screen
and (max-width: $screen-md-max) {
  .landing-header {
    padding: 150px 0;

    .product__title {
      font-size: 50px;
    }
  }

}

@media only screen
and (max-width: $screen-sm-max) {
  .landing-header {
    padding: 60px 0 40px;

    .product__title {
      font-size: 30px;
    }
  }
}

/* Mountain Theme: Intro Video Mobile
------------*/

.intro-video-mobile {
  padding: 15px 0;
  background: $btn-intro-video__background--mobile;
  text-align: center;

  .btn {
    border-radius: $btn-border-radius;
    background: $btn-intro-video__button-background--mobile;
    color: $btn-intro-video__button-color--mobile;
  }
}

.venobox {
  max-height: 100%;
}

/* Mountain Theme: Course Review
------------*/  

.course-review {
  padding: 80px 0;
  background: $course-review__background;
}

.course-review__wrapper,
.more-reviews-toggle {
  width: 100%;
  margin: 0;
}

.course-review__flex-grid-item {
  display: block;
  margin-bottom: 15px;
}

.course-review__container {
  position: relative;
  padding-top: 25px;
  background: $course-review-container__background;
  overflow: auto;
  
  & > * {
    padding: 0 25px;
  }

  & > .review--partial, 
  & > .review--complete {
    margin-bottom: 10px;
  }
}


.course-review__title {
  margin-top: 10px;
  font-size: 18px;
}

.course-review__text {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 1.5;
}

.course-review__name {
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 0 25px 10px;
  // background: $course-review-name__background;
  // font-size: 14px;
  font-weight: 600;
}

.review__show-more,
.review__show-less {
  margin: 5px 0;
  padding: 5px 0;
  border: none;
  background: transparent;
  color: $links__color;
  font-family: inherit;
  font-size: 15px;

  &:hover, 
  &:active {
    background: transparent;
    color: $links__color;
    text-decoration: underline;
  }
}

.course-review__button {
  padding-top: 20px;
  text-align: center;

  .review-button {
    margin-top: 0;
    padding: 1em;
    border-radius: $btn-border-radius;
    color: $course-review__read-more-color;
    background: $course-review__read-more-background;
    cursor: pointer;

    &:hover,
    &:active {
      color: $course-review__read-more-color--hover;
      background: $course-review__read-more-background--hover;
    }
 }
}

@media only screen
and (max-width: $screen-md-max) {

  .course-review__flex-grid-item {
    margin-bottom: 1em;
  }
  .course-review__container {
    margin-top: 0;
    margin-bottom: 1em;
  }
}


@media only screen
and (max-width: $screen-sm-max) {
  .course-review {
    padding: 1.5em 0 2em;
  }

  .course-review__text {
    font-size: 14px;
  }

  .course-review__title {
    font-size: 16px;
  }

  .review__show-more,
  .review__show-less {
    font-size: 14px;
  }
}


/* Mountain Theme: Course Instructor
------------*/

.course-instructor {
  padding: 60px 0;
  background: $course-instructor__background;
  background-position: bottom left;
  background-size: cover;
}

.instructor__details {
  overflow: auto;
}

.instructor__img {
  display: inline-block;
  max-width: 125px;
  max-height: 125px;
  margin-bottom: 25px;
  border: 1px solid #eaeaea;
  border-radius: 50%;
}

/* Mountain Theme: Product Overview
------------*/

.product-overview {
  padding: 60px 0;
  background: $product-overview__background;
}

/* Mountain Theme: Related Products
------------*/

.related-products {
  padding: 60px 0 80px;
  background: $related-products__background;

  h1 {
    &:before {
      background: $brand-accent-color;
    }
  }
}

.theme-mountain-multi-product__item {
  @include display(flex);
  @include align-items(center);
  @include justify-content(space-between);
  margin-bottom: 15px;
  padding: 20px;
  background: $related-products-product-item__background;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.theme-mountain-multi-product__section-title {
  color: $related-products__section-title;
}

.theme-mountain-multi-product__item + .theme-mountain-multi-product__item {
  margin-top: 10px;
}

.theme-mountain-multi-product__price {
  font-size: 26px;
  font-weight: 600;
}

.theme-mountain-multi-product__buy {}

.theme-mountain-multi-product__title {
  padding-right: 1em;
  padding-left: 1em;
}

.theme-mountain-multi-product__cta {
  padding: 8px 30px;
  background: $related-products__button-background;
  color: $related-products__button-color;
  border-radius: $btn-border-radius;

  &:hover, 
  &:active {
    background: lighten($related-products__button-background, 10%);
    color: $related-products__button-color;
  }
}


@media only screen
and (max-width: $screen-md-max) {

  .theme-mountain-multi-product__price {
    margin-bottom: 15px;
  }

  .theme-mountain-multi-product__item {
    display: block;
    padding: 10px;
    text-align: center;
  }

  .theme-mountain-multi-product__title {
    margin-bottom: 15px;
    padding-right: 0;
    padding-left: 0;
  }
}


/* Coupons
------------*/
.course-promotion {
  .coupon {
    padding: 20px 0;
    color: #ffffff;

    &.valid-coupon {
      background: $coupon__background;
    }

    &.invalid-coupon {
      background: $coupon-invalid__background;
      color: dimgrey;
    }
  }

}


/* Mountain Theme: Course Benefits
------------*/

.course-benefits {
  padding: 60px 0;
  background: $course-benefits__background;
}

.course-benefits__item {
  margin-bottom: 30px;

  p {
    margin-bottom: 0;
  }
}

@media only screen
and (max-width: $screen-sm-max) {
  .course-benefits__item {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}


/* Mountain Theme: Course Contents
------------*/

.course-contents {
  padding: 60px 0;
  background: $course-contents__background;
}

.course-contents-types {
  @include display(flex);
  @include justify-content(center);
  @include flex-flow(row wrap);
  padding: 0;
  list-style: none;
  text-align: center;
}

.course-contents__inner {
  padding: 25px 25px 42px;
  border: 1px solid #e9e9e9;
  background: $course-contents-inner__background;
}

.course-contents-types__item {
  @include flex-shrink(0);
  margin-bottom: 15px;
  padding: 0 20px;

  i {
    display: inline-block;
    margin-bottom: 10px;
    color: $course-contents__icon-color;
    font-size: 55px;
  }

  span {
    display: block;
  }
}


@media only screen
and (max-width: $screen-md-max) {
  .course-contents-types__item {
    @include flex-basis(25%);
    padding: 0;
  }
}

@media only screen
and (max-width: $screen-sm-min) {
  .course-contents__inner {
    padding-bottom: 25px;
  }

  .course-contents-types__item {
    @include flex-basis(50%);
    padding: 10px 0;
  }
}

/* Mountain Theme: Course Curriculum
------------*/

.course-curriculum {
  padding: 80px 0;
  background: $course-curriculum__background;
  color: $course-curriculum__color;

  a {
    color: inherit;

    &:hover {
      color: $course-curriculum__color--hover;
    }
  }

  ul {
    padding-left: 2em;
  }

  li {
    margin-bottom: 1em;
    list-style-type: disc;
  }

  .curriculum-chapter-header {
    padding-bottom: 18px;
    margin-bottom: 24px;
    color: $course-curriculum-chapter-header__color;
    border-bottom: $course-curriculum-header__border;
  }

  .chapter-list-item__title {
    line-height: 1.5;
  }

  .curriculum-chapter + .curriculum-chapter {
    margin-top: 42px;
  }

  .chapter-header {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
  }

  .chapter-list-item + .chapter-list-item {
    margin-top: 18px;
  }

  .chapter-time {
    display: inline-block;
    padding: 3px 20px;
    float: right;
    border-radius: 200px;
    background: $chapter-time__background;
    color: dynamic-text($chapter-time__background);
    font-size: 1.1rem;
    text-align: right;
  }

  .label-free {
    padding: 5px;
    background: $label-free__background;
    font-size: 0.9em;
  }
}

@media only screen
and (max-width: $screen-sm-min) {
  .course-curriculum {

    .chapter-header {
      display: block;
      margin-bottom: 15px;
    }

    .chapter-time {
      float: none;
      text-align: left;
    }
  }
}




// @import 'https://fonts.googleapis.com/css?family=Raleway';
.page-content-container {
  font-family: 'Open Sans', sans-serif !important;
}

h1 {
  font-weight: 100;
  text-transform: uppercase;

  &:before {
    display: block;
    position: relative;
    top: -20px;
    width: 60px;
    height: 3px;
    background: $headings__h1-decoration-background;
    content: '';
  }
}

.theme-mountain-multi-product__section-title {
  text-align: center;
}

.theme-mountain-multi-product__price {
  font-weight: 100;
}

.theme-mountain-multi-product__section-title::before {
  margin: 0 auto;
}

}


